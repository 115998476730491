interface Props {
  pathname: string;
}

export function ChestSvg(props: Props) {
  return (
    <svg
      fill="#FFF"
      className={`${props.pathname === "/daily-bonus" ? "active" : ""}`}
      style={{ height: "25%", width: "25%" }}
      viewBox="0 -35 512 512"
    >
      <title />
      <path d="M436.38,138c-21.53-46-67.93-74.16-118-76a21.68,21.68,0,0,0-2.17-.12H213.29c-46.31,0-89.6,10-120.79,47.4-26.42,31.71-31.34,68.29-31.34,107.72V432.42A17.9,17.9,0,0,0,78.8,450.06H431.63a17.9,17.9,0,0,0,17.64-17.64V290.93C449.27,242.52,457.48,183.15,436.38,138Zm-332.8,20c18.17-44.52,60-60.76,104.72-60.76H316.2c41,.85,78.47,25.82,92.24,65.11,6.28,17.94,5.55,36.12,5.55,54.72v21.3H96.42C96.23,212,93.49,182.71,103.58,158ZM218.39,273.65H292v33H218.39ZM123.2,414.77H96.44V273.64h86.67v50.67A17.9,17.9,0,0,0,200.75,342H309.68a17.67,17.67,0,0,0,17.64-17.64V273.64H414V414.77Z" />
    </svg>
  );
}

export default ChestSvg;
